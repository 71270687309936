<template>
  <div>
    <label
      class="text-left block"
      :class="
        isBig ? 'font-sans text-sm md:text-base font-medium' : 'list-label'
      "
    >
      <span v-if="label" class="ml-4">
        {{ required ? `${$t(label)}*` : $t(label) }}
      </span>
      <CommonTooltip v-if="label && tooltip" :text="$t(tooltip)" class="ml-1">
        <CommonFontAwesomeIcon
          class="text-gray-400"
          icon="fa-solid fa-circle-info"
        />
      </CommonTooltip>
      <select
        class="select-field w-full border px-4 !font-medium invalid:text-gray-400 article-label focus-within:outline-none focus-within:ring"
        :class="{
          'rounded-md': false,
          'rounded-full': !false,
          'mt-2 py-4': isBig,
          'mt-1 py-2': !isBig,
          'text-gray-400': !modelValue,
        }"
        :required="required"
        @change="handleSelect"
      >
        <option value="" disabled selected>
          {{ placeholder ? $t(placeholder) : '' }}
        </option>
        <option
          v-for="(item, index) in items"
          :key="index"
          :value="item.id"
          :selected="item.id === modelValue"
        >
          {{ item.name }}
        </option>
      </select>
    </label>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  items: { id: number; name: string }[]
  modelValue: number
  placeholder?: string
  required?: boolean
  label?: string
  tooltip?: string
  isBig?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

function handleSelect(event: Event) {
  const target = event.target as HTMLSelectElement
  emit('update:modelValue', Number(target.value))
}
</script>

<style scoped>
.select-field {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.871 1.896-3.225 3.25a.895.895 0 0 1-.583.229.804.804 0 0 1-.584-.229l-3.225-3.25a.786.786 0 0 1-.203-.888A.832.832 0 0 1 1.813.5h6.474c.33 0 .61.203.736.508.127.304.077.66-.152.888Z' fill='%23282828'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  @apply appearance-none;
}
</style>
